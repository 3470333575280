.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.row-l {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.row-sa {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.row-r {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.row-sb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.coll {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.col-t {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.col-b {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.col-sa {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.col-sb {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}


.vertical-lt {
  align-items: flex-start;
}

.vertical-rb {
  align-items: flex-end;
}

.hidden {
  display: none;
}

.tc {
  text-align: center;
}

.tl {
  text-align: left;
}

.tr {
  text-align: right;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.hc100 {
  height: 100vh;
}

.bg-c000 {
  background-color: #000;
}

.bg-cfff {
  background-color: #fff;
}

.c000 {
  color: #000;
}

.c333 {
  color: #333;
}

.c666 {
  color: #666;
}

.c999 {
  color: #999;
}

.cccc {
  color: #cccc;
}

.cfff {
  color: #fff;
}

.fz10 {
  font-size: 10px;
}

.fz11 {
  font-size: 11px;
}

.fz12 {
  font-size: 12px;
}

.fz13 {
  font-size: 13px;
}

.fz14 {
  font-size: 14px;
}

.fz15 {
  font-size: 15px;
}

.fz16 {
  font-size: 16px;
}

.fz17 {
  font-size: 17px;
}

.fz18 {
  font-size: 18px;
}

.fz20 {
  font-size: 20px;
}

.fz22 {
  font-size: 22px;
}

.fz24 {
  font-size: 24px;
}

.fz26 {
  font-size: 26px;
}

.fz28 {
  font-size: 28px;
}

.fz32 {
  font-size: 32px;
}

.fz36 {
  font-size: 36px;
}

.margin-t10 {
  margin-top: 10px;
}

.margin-t20 {
  margin-top: 20px;
}

.margin-b5 {
  margin-bottom: 5px;
}

.margin-b10 {
  margin-bottom: 10px;
}

.margin-b20 {
  margin-bottom: 20px;
}

.margin-r10 {
  margin-right: 10px;
}

.margin-r20 {
  margin-right: 20px;
}


.padding-l5 {
  padding-left: 5px;
}

.padding-l10 {
  padding-left: 10px;
}

.padding-l20 {
  padding-left: 20px;
}

.padding-t5 {
  padding-top: 5px;
}

.padding-t10 {
  padding-top: 10px;
}

.padding-t20 {
  padding-top: 20px;
}

.padding-t25 {
  padding-top: 25px;
}

.padding-r5 {
  padding-right: 5px;
}

.padding-r10 {
  padding-right: 10px !important;
}

.padding-r20 {
  padding-right: 20px;
}

.padding-b5 {
  padding-bottom: 5px;
}

.padding-b10 {
  padding-bottom: 10px;
}

.padding-b20 {
  padding-bottom: 20px;
}

.padding-b25 {
  padding-bottom: 25px;
}

.padding0 {
  padding: 0;
}

.padding2 {
  padding: 2px !important;
}

.padding5 {
  padding: 5px !important;
}

.padding7 {
  padding: 7px !important;
}

.padding10 {
  padding: 10px;
}

.padding15 {
  padding: 15px;
}


.padding20 {
  padding: 20px;
}

.padding25 {
  padding: 25px;
}

.padding30 {
  padding: 30px;
}


.padding-tb5 {
  padding: 5px 0;
}

.padding-tb10 {
  padding: 10px 0;
}

.padding-tb20 {
  padding: 20px 0;
}

.padding-lr10 {
  padding: 0 10px;
}

.padding-lr5 {
  padding-right: 5px;
  padding-left: 5px;
}

.padding-lr3 {
  padding: 0 3px;
}

.padding-a510 {
  padding: 5px 10px;
}

.padding-lr20 {
  padding: 0 20px;
}

.padding0 {
  padding: 0 !important;
}

.ziti {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.ww70 {
  width: 70%;
}

.ww50 {
  width: 50%;
}

.ww25 {
  width: 25%;
}

.ww25 {
  width: 25%;
}

.ww20 {
  width: 20%;
}

.w220 {
  width: 220px;
}

.w150 {
  width: 150px;
}


.content-padding {
  padding: 10px;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipsis-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ellipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.font-underline {
  text-decoration: underline;
}

.no-select {
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

.ant-table-title {
  padding: 0 !important;
  margin: 0;
}

.ant-table-thead>tr>th {
  font-weight: bolder;
}

.ant-table-thead>tr>th:last-child {
  border-right: 1px solid rgb(179, 179, 179) !important;
}

.ant-table-row>td {
  border-right: 1px solid rgb(179, 179, 179) !important;
}

.height {
  width: 100%;
  height: 100%;
  line-height: 100%;
}

.round {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  position: relative;
  right: 8px;
  top: 50%;
}

.shutDown {
  position: absolute;
  left: 45%;
  bottom: -40px;
}

.left {
  padding-right: 5px;
  padding-bottom: 10px;
  width: 80px;
  height: 100%;
}

.right {
  padding-left: 5px;
  padding-bottom: 10px;
  width: 70%;
  height: 100%;
  border-left: 1px solid #6666;
}

.bold {
  font-weight: bold;
}

.ant-table-tbody>tr.ant-table-row:hover>td {
  background: none;
}

.position {
  position: relative;
}

.foot1 {
  padding: 30px 0;
}

.foot2 {
  position: fixed;
  bottom: 50px;
  /* padding-top: 300px; */
}

.visited {
  height: 300px;
  overflow: scroll;
  overflow-y: scroll;
}

.lin:not(:last-child) {
  border-bottom: 1px solid #ddd;
}

.lin2:not(:last-child) {
  border-bottom: 1px solid #ddd;
}

.lin2:last-child {
  color: red;
}

.opacity0 {
  opacity: 0;
}