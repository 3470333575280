body {
  background-color: rgba(248, 248, 248);
}

.page {
  width: 100vw;
  padding: 2vw;
}

.head {
  position: relative;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -999;
  height: 125px;
}

.content {
  width: 100%;
  border-radius: 6px;
  padding: 12px 10px;
  background-color: #fff;
  margin-bottom: 18px;
}

.state-img {
  width: 24px;
  height: 24px;
  margin-left: 10px;
  margin-right: 18px;
}

.create-button {
  margin-right: 10px;
  /* width: 100px; */
  height: 30px;
  color: #fff;
  background-color: transparent;
  border: solid 2px;
  border-radius: 10px;
  cursor: pointer;
}

.icon {
  width: 20px;
  height: 24px;
  margin-right: 10px;
}

.margin-top-6 {
  margin-top: 6px;
}

.product-photo {
  width: 80px;
  height: 80px;
  margin-right: 10px;
  border-radius: 6px;
}

.underline {
  text-decoration: line-through;
}

@media screen and (max-width: 767px) {
  .img {
    width: 100%;
  }
}

.values {
  padding: 6px;
  font-size: 15px;
  border: 2px solid rgb(179, 179, 179);
}

.tableTit {
  background-color: rgb(230, 230, 230) !important;
  padding: 0 !important;
  line-height: 10px;
  text-align: left !important;
  font-size: larger !important;
}

.space {
  padding: 4px 3px !important;
  line-height: 12px;
  font-size: small;
  /* background-color: white; */
  border: 1px solid rgb(179, 179, 179);
  white-space: pre;
}


.cell {
  width: 100%;
  border: 2px solid rgb(179, 179, 179);
}

.cell>tr>td {
  width: 5%;
  line-height: 12px;
  font-weight: bolder;
}

.titLeft {
  text-align: left;
}

.titRight {
  text-align: right;
}

.kindName {
  padding: 0 5px;
}

.priviewBox {
  position: fixed;
  left: 30%;
  top: 15%;
  width: 550px;
  height: 550px;
  background: rgb(199, 138, 138);
  visibility: hidden;
}

.priviewPic {
  width: 100%;
  height: 100%;

}

.close {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
  background: url(../../resource/img/shutDown.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

.tableTitle {
  font-size: 20px;
  font-weight: bold;
  background-color: rgb(230, 230, 230);
  border: 1px solid rgb(179, 179, 179);
}

.tableRow {
  /* background-color: rgb(240, 240, 240); */
}


.count {
  margin-top: 10px;
  width: 100%;
  font-size: 16px;
}

.count1 {
  padding: 10px;
  width: 15%;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  border: 2px solid rgb(179, 179, 179);
  background-color: rgb(245, 245, 30);
}

.count2 {
  padding: 10px;
  width: 60%;
  color: red;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  border: 2px solid rgb(179, 179, 179);
  background-color: rgb(245, 245, 30);
}

.count3 {
  padding: 10px;
  width: 25%;
  color: red;
  line-height: 24px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  border: 2px solid rgb(179, 179, 179);
  background-color: rgb(245, 245, 30);
}

.count4 {
  font-size: 18px;
  /* font-weight:normal; */
}

.attention1 {
  padding: 10px;
  width: 100%;
  color: red;
  line-height: 24px;
  font-size: 18px;
  text-align: center;
  border: 2px solid rgb(179, 179, 179);
  border-top: none;
  background-color: rgb(245, 245, 30);
}

.code {
  /* background-color: red; */
}

.code>div {
  position: relative;
  margin-right: 10px;
  width: 236px;
  height: 355px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* background-image: url('../../resource/img/xbwx.png'); */
}

.collectw {
  background-image: url('../../resource/img/xbwx.png');
}

.collectz {
  background-image: url('../../resource/img/xbzfb.png');
}

.code>div>canvas {
  z-index: 0 !important;
  width: 150px !important;
  height: 150px !important;
  position: absolute;
  left: 41px;
  bottom: 70px;
}

.code>div>img {
  z-index: 999 !important;
  width: 150px !important;
  height: 150px !important;
  position: absolute;
  left: 41px;
  bottom: 70px;
  display: block;
}