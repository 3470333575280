.color1 {
  color: white;
}

.color2 {
  color: red;
}

.color3 {
  color: #696969;
}

.color4 {
  color: #6666;
}

.color5 {
  color: rgb(160, 156, 156);
}

.color6 {
  color: #be4242;
}

.rgb210 {
  color: rgb(210, 210, 210);
}

.back-color0 {
  z-index: 9999;
  background: aqua !important;
  /* pointer-events: none; */
}

/* .back-color0:hover {
  background-color: aqua !important;
} */

.back-color1 {
  background-color: rgb(240, 240, 240);
}

.back-color2 {
  background-color: #fff;
}

.test {
  color: #838357;
}